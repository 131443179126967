import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component'
import { WebsiteComponent } from './website.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { WebsiteGuard } from '../auth/website-guard'
import { NoAuthGuard } from '../auth/no-auth-guard'

const routes: Routes = [
    {
        path: '',
        component: WebsiteComponent,
        canActivate: [WebsiteGuard],
        children: [
            {
                path: '',
                component: WebsiteComponent,
                loadChildren: () => import('./login/login.module').then((mod) => mod.LoginModule)
            },
            {
                path: 'login',
                canActivate: [NoAuthGuard],
                loadChildren: () => import('./login/login.module').then((mod) => mod.LoginModule)
            },
            {
                path: 'forgot-password',
                canActivate: [NoAuthGuard],
                loadChildren: () =>
                    import('./forgot-password/forgot-password.module').then(
                        (mod) => mod.ForgotPasswordModule
                    )
            },
            {
                path: 'registration',
                canActivate: [NoAuthGuard],
                loadChildren: () =>
                    import('./sign-up/registration/registration.module').then(
                        (mod) => mod.RegistrationModule
                    )
            },
            {
                path: 'registration-success',
                loadChildren: () =>
                    import('./sign-up/signup-success/signup-success.module').then(
                        (mod) => mod.SignupSuccessModule
                    )
            },
            {
                path: 'reset-password/:code',
                canActivate: [NoAuthGuard],
                loadChildren: () =>
                    import('./reset-password/reset-password.module').then(
                        (mod) => mod.ResetPasswordModule
                    )
            },

            {
                path: 'online-payment/:data',
                canActivate: [WebsiteGuard],
                loadChildren: () =>
                    import('./online-payment/online-payment.module').then(
                        (mod) => mod.OnlinePaymentModule
                    )
            },
            {
                path: 'verify-email/:code',
                loadChildren: () =>
                    import('./verify-email/verify-email.module').then(
                        (mod) => mod.VerifyEmailModule
                    )
            },
            {
                path: 'contact-us',
                loadChildren: () =>
                    import('./contact-us/contact-us.module').then((mod) => mod.ContactUsModule)
            },
            {
                path: 'pages/:route',
                loadChildren: () => import('./pages/pages.module').then((mod) => mod.PagesModule)
            },
            {
                path: 'faqs',
                loadChildren: () => import('./faqs/faqs.module').then((mod) => mod.FaqsModule)
            },
            {
                path: 'stripe-payment-initiate/:id',
                loadChildren: () => import('./stripe-payment-initiate/stripe-payment-initiate.module')
                    .then((mod) => mod.StripePaymentInitiateModule)
            },
            {
                path: 'stripe-payment-status/:status',
                loadChildren: () => import('./stripe-payment-status/stripe-payment-status.module')
                    .then((mod) => mod.StripePaymentStatusModule)
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: { message: 'From Website' }
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WebsiteRoutes { }
