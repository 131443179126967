import { ApiService } from './../services/api.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit, Renderer2 } from '@angular/core'
import { WebsiteService } from '../services/website.service'
import { ConstantsService } from '../services/constants.service'

@Component({
    selector: 'app-website',
    templateUrl: './website.component.html',
    styleUrls: ['./website.component.css']
})
export class WebsiteComponent implements OnInit {
    isLoading: boolean

    constructor(
        public web: WebsiteService,
        public api: ApiService,
        public cs: ConstantsService,
        public router: Router,
        private route: ActivatedRoute,
        public renderer2: Renderer2,
    ) {
    }

    ngOnInit() {
        if (this.router.url === '/') {
            if (this.api.isAuthenticated()) {
                const resp = {
                    data: this.api.user
                }
                this.api.doUserRedirects(resp, this.router)
            } else {
                this.router.navigateByUrl('login')
            }
        }
    }
}
